import LoginComponent from '@components/Login/LoginComponent.vue';
import { Vue } from '@master';

const login = new Vue({
    el: '#login',
    title: "Login",
    methods: {
        loginSuccess($event) {
            window.location.href = $event.redirect;
        }
    },
    components: {
        'login': LoginComponent
    }
});
